<template>
  <AppLayout>
    <!-- <router-view :key="$route.fullPath"/> -->
    <router-view />
  </AppLayout>
</template>

<script>
// @ is an alias to /src
import AppLayout from "@/layouts/AppLayout";
import { useStore } from "vuex";
import { reactive, computed, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/src/cookieconsent.js'
// import fitvids from 'fitvids'

export default {
  name: "App",
  components: {
    AppLayout,
  },

  setup() {
    const route = useRoute();

    var siteData = reactive({
      seo_title: `Semineu pe lemne la preturi mici, seminee PeFoc rustice, moderne`,
      seo_description: `La PeFoc ai o gama diversificata de seminee moderne, termoseminee, sobe pe lemne, focare seminee, usi de semineu la preturi accesibile. Vezi oferta noastra.`,
      seo_keywords: `termosemineu, semineu centrala, cu serpentina, focar, pe colt, calorifere, lemne, pardosea, incalzire, toata casa`,
      seo_image: require("@/assets/images/header-slider.jpg"),
      robots: "index, folow",
      canonical_url: window.location.origin + route.fullPath,
    });
    const store = useStore();

    // console.log(siteData);

    useHead({
      // Can be static or computed
      title: computed(() => siteData.seo_title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.seo_description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.seo_keywords),
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.seo_title),
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: `og:url`,
          content: window.location.origin + route.fullPath,
        },
        {
          property: "og:image",
          content: computed(() => siteData.seo_image),
        },
      ],
      link: [
        {
          rel: `canonical`,
          href: computed(() => {
            return siteData.canonical_url
              ? window.location.origin + siteData.canonical_url
              : window.location.origin + route.fullPath;
          }),
        },
      ],
    });
    const updateSiteData = async () => {
      // console.log('--->> Seo_params_state is Updated');
      siteData.seo_description = store.getters["seo/seo_description"];
      siteData.seo_title = store.getters["seo/seo_title"];
      siteData.seo_keywords = store.getters["seo/seo_keywords"];
      siteData.canonical_url = store.getters["seo/canonical_url"];
      siteData.seo_image = store.getters["seo/seo_image"];
    };

    watch(updateSiteData);

    return {
      updateSiteData,
      siteData,
    };
  },

  mounted() {
    this.$store.dispatch("shop/getCart").then((data) => {
      // Redirect if cart empty
      if(this.$route.name == "Checkout" && data.length < 1) {
        this.$router.push({ name: "Cart" });
      }
      
      this.$store.dispatch("shop/actCartIsLoaded", true);
    });
    this.$store.dispatch("shop/getCurrency");
    // fitvids()
    // Cookie Consent
    const cc = window.initCookieConsent();
    cc.run({
      languages: {
          'en': {
              consent_modal: {
                  description: 'Acest site foloseşte cookies! Continuarea navigării implică acceptarea lor. <a target="_blank" href="https://www.pefoc.ro/privacy-and-cookies.html">Vezi detalii</a>',
                  primary_btn: {
                      text: 'Am inteles',
                      role: 'accept_all'              // 'accept_selected' or 'accept_all'
                  },
              },
              settings_modal: {
                  title: 'Cookie preferences',
                  save_settings_btn: 'Save settings',
                  accept_all_btn: 'Accept all',
                  reject_all_btn: 'Reject all',
                  close_btn_label: 'Close',
                  // cookie_table_caption: 'Cookie list',
                  cookie_table_headers: [
                      {col1: 'Name'},
                      {col2: 'Domain'},
                      {col3: 'Expiration'},
                      {col4: 'Description'}
                  ],
                  blocks: [
                      {
                          title: 'Cookie usage 📢',
                          description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                      }, {
                          title: 'Strictly necessary cookies',
                          description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                          toggle: {
                              value: 'necessary',
                              enabled: true,
                              readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                          }
                      }, {
                          title: 'Performance and Analytics cookies',
                          description: 'These cookies allow the website to remember the choices you have made in the past',
                          toggle: {
                              value: 'analytics',     // your cookie category
                              enabled: false,
                              readonly: false
                          },
                          cookie_table: [             // list of all expected cookies
                              {
                                  col1: '^_ga',       // match all cookies starting with "_ga"
                                  col2: 'google.com',
                                  col3: '2 years',
                                  col4: 'description ...',
                                  is_regex: true
                              },
                              {
                                  col1: '_gid',
                                  col2: 'google.com',
                                  col3: '1 day',
                                  col4: 'description ...',
                              }
                          ]
                      }, {
                          title: 'Advertisement and Targeting cookies',
                          description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                          toggle: {
                              value: 'targeting',
                              enabled: false,
                              readonly: false
                          }
                      }, {
                          title: 'More information',
                          description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                      }
                  ]
              }
          }
      }
    });

  },

};
</script>

<style>
@import "./assets/css/common.css";
@import "./assets/css/style.css";
</style>

<style lang="scss">
@import "./assets/scss/style.scss";
</style>

<style>
:root {
    /** Change button primary color to black **/
    --cc-bg: #c0050f ;
    --cc-btn-primary-bg: #c0050f !important;
    --cc-btn-primary-border-color: #fff !important;
    --cc-btn-primary-hover-bg: #c0050f !important;
    --cc-btn-primary-hover-border-color: #fff !important;
    --cc-btn-primary-hover-color: #fff !important;
    --cc-btn-border-radius: 10px;

    /** Also make toggles the same color as the button **/
    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
}

#cc_div{ 
  --cc-btn-primary-text: #fff; 
  color: #fff !important; 
  font-family: Helvetica,Calibri,Arial,sans-serif !important;
  font-size: 15px !important; 
}

#c-bns button:first-child{
  color: #fff !important; 
}

.c-bn{ 
  border-radius: 0% !important; 
  border: 1.5px solid !important; 
  border-color: white !important; 
}

#c-txt{ 
  font-size: 15px !important;
}

#cm{ 
  border-radius: 0% !important; 
}
</style>